import React, { useEffect, useState } from "react";

import { Nav } from "react-bootstrap";
import Header from "../../components/Header/Header";
import styles from "./safety.module.scss";

const Safety = (props) => {
  const {
    updatePopUpData,

    updateCurrentFormStage,

    updateMailCampaignUser,

    sessionData,

    formStage,

    getQuoteClicked,
  } = props;

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 900);
  const [emailID, setemailID] = useState("");
  const [showTab, setShowTab] = useState(false);
  const [userGroups, setuserGroups] = useState([]);

  useEffect(() => {
    async function authentication() {
      try {
        let email = sessionData?.attributes?.email?.split("@")[0];

        console.log(email);

        setemailID(email);

        let userGroups =
          sessionData.signInUserSession.accessToken.payload["cognito:groups"];

        if (userGroups?.includes(process.env.REACT_APP_DEMO_GROUP)) {
          setIsDemoLogin(true);
        }

        setuserGroups({ userGroups });

        console.log("emailID: ", emailID, userGroups);
      } catch (error) {
        console.log("comp did update err", error);
      }

      if (emailID) {
        setuserGroups({ emailID });
      }
    }

    authentication();
  }, [sessionData, emailID]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 900);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      {isMobileView ? (
        <nav className="navwidth newquote-sidebar bg-transparent">
          <input
            type="checkbox"
            id="menu"
            name="menu"
            className="m-menu__checkbox bg-white"
          />

          <label className="m-menu__toggle" htmlFor="menu">
            <svg
              width="35"
              height="35"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#000"
              strokeWidth="2"
              strokeLinecap="butt"
              strokeLinejoin="arcs"
            >
              <line x1="3" y1="12" x2="21" y2="12"></line>

              <line x1="3" y1="6" x2="21" y2="6"></line>

              <line x1="3" y1="18" x2="21" y2="18"></line>
            </svg>
          </label>

          <label className="m-menu__overlay" htmlFor="menu"></label>

          <div className="m-menu">
            <div className="m-menu__header">
              <label className="m-menu__toggle" htmlFor="menu">
                <svg
                  width="35"
                  height="35"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#000000"
                  strokeWidth="2"
                  strokeLinecap="butt"
                  strokeLinejoin="arcs"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>

                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </label>

              <span></span>
            </div>

            <Nav.Item>
              <Nav.Link className="sidebar-heading">
                <div className="d-flex align-items-center text-white pt-3">
                  <span className="LoginUser">{emailID}</span>

                  <span>
                    {isMobileView ? (
                      <li className="no-bullets">
                        <a
                          className="dropdown-item"
                          onClick={() => {
                            logout();
                          }}
                        >
                          Logout <i className="bi-person-fill"></i>
                        </a>
                      </li>
                    ) : (
                      " "
                    )}
                  </span>
                </div>
              </Nav.Link>
            </Nav.Item>

            <hr
              style={{
                width: "90%",

                backgroundColor: "grey",
              }}
            />

            {isMobileView ? (
              <div>
                <a
                  className="dashboard-link-mobile text-white"
                  href={"/Dashboard"}
                >
                  Dashboard
                </a>
              </div>
            ) : (
              ""
            )}
          </div>
        </nav>
      ) : (
        <div className={`d-flex bg-light ${styles.dashboardHeader}`}>
          <Header sessionData={sessionData} showTab={showTab} />
        </div>
      )}

      <div className={styles.header_border}></div>

      <div className={styles.workers_header}>
        <div className={styles.heading}> Safety Video </div>

        <div>
          <div className={styles.sub_header}>SAIC</div>

          <a
            href="https://www.serviceinsurance.com/risk-control-resource-center/"
            target="_blank"
          >
            <div className={styles.link_style}>
              Risk Control Resource Center - Service Insurance Companies
            </div>
          </a>
        </div>
        <div>
          <div className={styles.sub_header}>Amtrust</div>

          <a
            href="https://amtrustfinancial.com/loss-control/streaming-videos/streaming-videos-access"
            target="_blank"
          >
            <div className={styles.link_style}>
              Workplace Safety Training Resources | AmTrust Insurance
              (amtrustfinancial.com)
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Safety;
