import React, { useEffect, useState } from "react";

import { Nav } from "react-bootstrap";
import Header from "../../components/Header/Header";
import styles from "./resources.module.scss";
import englishHandbook from "../../assets/images/EmployeeHandbook.docx";
import SpanishHandbook from "../../assets/images/SPANISHEmployeeHandbook.docx";

const Resources = (props) => {
  const { sessionData } = props;

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 900);
  const [emailID, setemailID] = useState("");
  const [showTab, setShowTab] = useState(false);
  const [userGroups, setuserGroups] = useState([]);

  useEffect(() => {
    async function authentication() {
      try {
        let email = sessionData?.attributes?.email?.split("@")[0];

        console.log(email);

        setemailID(email);

        let userGroups =
          sessionData.signInUserSession.accessToken.payload["cognito:groups"];

        if (userGroups?.includes(process.env.REACT_APP_DEMO_GROUP)) {
          setIsDemoLogin(true);
        }

        setuserGroups({ userGroups });

        console.log("emailID: ", emailID, userGroups);
      } catch (error) {
        console.log("comp did update err", error);
      }

      if (emailID) {
        setuserGroups({ emailID });
      }
    }

    authentication();
  }, [sessionData, emailID]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 900);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      {isMobileView ? (
        <nav className="navwidth newquote-sidebar bg-transparent">
          <input
            type="checkbox"
            id="menu"
            name="menu"
            className="m-menu__checkbox bg-white"
          />

          <label className="m-menu__toggle" htmlFor="menu">
            <svg
              width="35"
              height="35"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#000"
              strokeWidth="2"
              strokeLinecap="butt"
              strokeLinejoin="arcs"
            >
              <line x1="3" y1="12" x2="21" y2="12"></line>

              <line x1="3" y1="6" x2="21" y2="6"></line>

              <line x1="3" y1="18" x2="21" y2="18"></line>
            </svg>
          </label>

          <label className="m-menu__overlay" htmlFor="menu"></label>

          <div className="m-menu">
            <div className="m-menu__header">
              <label className="m-menu__toggle" htmlFor="menu">
                <svg
                  width="35"
                  height="35"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#000000"
                  strokeWidth="2"
                  strokeLinecap="butt"
                  strokeLinejoin="arcs"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>

                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </label>

              <span></span>
            </div>

            <Nav.Item>
              <Nav.Link className="sidebar-heading">
                <div className="d-flex align-items-center text-white pt-3">
                  <span className="LoginUser">{emailID}</span>

                  <span>
                    {isMobileView ? (
                      <li className="no-bullets">
                        <a
                          className="dropdown-item"
                          onClick={() => {
                            logout();
                          }}
                        >
                          Logout <i className="bi-person-fill"></i>
                        </a>
                      </li>
                    ) : (
                      " "
                    )}
                  </span>
                </div>
              </Nav.Link>
            </Nav.Item>

            <hr
              style={{
                width: "90%",

                backgroundColor: "grey",
              }}
            />

            {isMobileView ? (
              <div>
                <a
                  className="dashboard-link-mobile text-white"
                  href={"/Dashboard"}
                >
                  Dashboard
                </a>
              </div>
            ) : (
              ""
            )}
          </div>
        </nav>
      ) : (
        <div className={`d-flex bg-light ${styles.dashboardHeader}`}>
          <Header sessionData={sessionData} showTab={showTab} />
        </div>
      )}

      <div className={styles.header_border}></div>

      <div className={styles.workers_header}>
        <div className={styles.heading}> HR Resources</div>
        {/* <div className={styles.headcontent}>
          From  Drug Free Workplace to Experience Mods, this resource center
          links you to valuable resources in understanding the components of
          your workers’ compensation insurance program.
        </div> */}
        <div>
          <div className={styles.link_style}>
            <a href="https://www.employer.gov/" target="_blank">
              <span className={styles.sub_header1}> Employer.gov</span>
            </a>{" "}
            <span style={{ color: "#5a63a3" }}>: </span>
            <span> </span>
            Employer.gov is the U.S. Department of Labor’s resource center for
            Job Creators. This site provides answers to common questions about
            workers and business.
          </div>

          <div className={styles.link_style1}>
            <a
              href="https://webapps.dol.gov/elaws/PostersRecordKeeping.html"
              target="_blank"
            >
              <span className={styles.sub_header1}>Employment Posters</span>{" "}
            </a>
            <span style={{ color: "#5a63a3" }}>: </span>
            <span> </span>
            This link takes you for the First Steps tool from the Department of
            Lab or to provide guidance on necessary forms.
          </div>
          {/* <div className={styles.link_style2}>
            <a
              href="https://www.serviceinsurance.com/claim-services/"
              target="_blank"
            >
              <div className={styles.sub_header2}>
                Service Insurance Group Self Service Posting
              </div>{" "}
            </a>
            Disclaimer: Risk Transfer PEO users please do not use this to file a
            claim with this URL as this is not TPA specific and Risk Transfer
            has dedicated phone, fax, email addresses for the PEO program by
            TPA.
            <span> </span>
          </div> */}

          <div className={styles.link_style}>
            <a
              href="https://webapps.dol.gov/elaws/advisors.html"
              target="_blank"
            >
              <span className={styles.sub_header1}>US DOL Advisors</span>{" "}
            </a>
            <span style={{ color: "#5a63a3" }}>: </span>
            <span> </span>
            The elaws (Employment Laws Assistance for Workers and Small
            Businesses) Advisors are a set of interactive, online tools
            developed by the U.S. Department of Labor (DOL) to help employers
            and employees learn more about their rights and responsibilities
            under numerous Federal employment laws. Each elaws Advisor mimics
            the interaction someone would have with an employment law expert by
            asking specific questions and providing tailored information based
            on individual situations and circumstances. Depending on the topic,
            questions might pertain to industry, staff size, and how long a
            particular employee has worked for his or her employer. All
            questions offer pre-set answers to choose from, and no information
            provided is recorded or stored in any system.
          </div>

          <div className={styles.link_style}>
            <a
              href="https://www.dol.gov/agencies/whd/minimum-wage/state"
              target="_blank"
            >
              <span className={styles.sub_header1}>Minimum Wage Map</span>{" "}
            </a>
            <span className={styles.sub_header1}></span>{" "}
            <span style={{ color: "#5a63a3" }}>: </span>
            <span> </span>
            This link takes you to the Division of Wage and Hour state map
          </div>

          <div className={styles.link_style}>
            <a href="https://www.careeronestop.org/" target="_blank">
              <span className={styles.sub_header1}>CareerOneStop</span>{" "}
            </a>
            <span style={{ color: "#5a63a3" }}>: </span>
            <span> </span>
            This link takes you to a wage comparison tool by occupation.  This
            tool is valuable in assessing if the employees of client companies
            are being appropriate compensated against the Bureau of Labor
            Statistics wage survey for the region.
          </div>

          <div className={styles.link_style}>
            <span> </span>
            Employee Handbook Template: Access a thorough handbook template that
            can be customized for employer use. It is recommended that any use
            of this be reviewed by a employment law professional. Available in
            <br />
            both{" "}
            <a href="https://risktransfer.egnyte.com/dl/195DtiCxsc" target="_blank">
              <span className={styles.sub_header1}>English</span>
            </a>{" "}
            and{" "}
            <a href="https://risktransfer.egnyte.com/dl/lhsy6qe5Q9" target="_blank">
              <span className={styles.sub_header1}>Spanish</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resources;
