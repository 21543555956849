import React, { useState, useEffect, lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

const UnderWriterFlow = lazy(() => import("./pages/UwPortal/UnderWriterFlow"));
const NewQuote = lazy(() => import("./pages/Quote/NewQuote"));
const Login = lazy(() => import("./pages/Login/Login"));
const DashboardLibertateTab = lazy(() =>
  import("./pages/Dashboard/DashboardLibertateTab")
);
const DashboardRater = lazy(() =>
  import("./pages/RaterDashboard/DashboardRater")
);
const PrivateGroupRoute = lazy(() =>
  import("./components/PrivateGroupRoute/PrivateGroupRoute")
);
const NewRates = lazy(() => import("./pages/Rates/NewRates"));
const DataUpload = lazy(() => import("./pages/DataUpload/DataUpload"));
const RaterUpload = lazy(() => import("./pages/RaterUpload/RaterUpload"));
const RiskProfile = lazy(() =>
  import("./pages/RiskProfile/RiskProfile/RiskProfile")
);
const DemoRouteHandler = lazy(() => import("./pages/Demo/DemoRouteHandler"));

import { LibertateContextProvider } from "./context/ContextProvider";
import { Auth } from "aws-amplify";
import Loader from "./library/Loader/Loader";
import HomePage from "./pages/HomePage/HomePage";
import WorkersPage from "./pages/workersPage/WorkersPage";
import Resources from "./pages/HRResources/Resources";
import Safety from "./pages/safety/Safety";

function App(props) {
  const [sessionData, setSessionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userGroups, setUserGroups] = useState([]);

  useEffect(() => {
    if (loading) {
      (async () => {
        try {
          let sessionData = await Auth.currentAuthenticatedUser();
          let userGroups =
            sessionData.signInUserSession.accessToken.payload["cognito:groups"];
          setSessionData(sessionData);
          setUserGroups(userGroups);
        } catch (error) {}
        setLoading(false);
      })();
    }
  }, []);

  return !loading ? (
    <LibertateContextProvider>
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route
              path="/"
              element={
                sessionData &&
                userGroups.includes(process.env.REACT_APP_LANDING_GROUP) ? (
                  <Navigate to="/landingpage" />
                ) : sessionData &&
                  !userGroups.includes(process.env.REACT_APP_LANDING_GROUP) ? (
                  <Navigate to="/dashboard" />
                ) : (
                  <Login sessionData={sessionData} />
                )
              }
            />

            <Route path="/demo" element={<DemoRouteHandler />} />

            <Route path="/riskprofile" element={<RiskProfile />} />

            <Route
              path="/Quote"
              element={
                <PrivateGroupRoute
                  allowedGroups={[process.env.REACT_APP_GET_QUOTE_GROUP]}
                  sessionData={sessionData}
                >
                  <NewQuote sessionData={sessionData} />
                </PrivateGroupRoute>
              }
            />

            <Route
              path="/landingpage"
              element={
                <PrivateGroupRoute
                  allowedGroups={[
                    process.env.REACT_APP_UPLOAD_COMPANY,
                    process.env.REACT_APP_UPLOAD_RATER,
                  ]}
                  sessionData={sessionData}
                >
                  <HomePage sessionData={sessionData} />
                </PrivateGroupRoute>
              }
            />

            <Route
              path="/workers"
              element={
                <PrivateGroupRoute
                  allowedGroups={[
                    process.env.REACT_APP_UPLOAD_COMPANY,
                    process.env.REACT_APP_UPLOAD_RATER,
                  ]}
                  sessionData={sessionData}
                >
                  <WorkersPage sessionData={sessionData} />
                </PrivateGroupRoute>
              }
            />

            <Route
              path="/Dashboard"
              element={
                <PrivateGroupRoute
                  allowedGroups={[process.env.REACT_APP_DASHBOARD_GROUP]}
                  sessionData={sessionData}
                >
                  <DashboardLibertateTab sessionData={sessionData} />
                </PrivateGroupRoute>
              }
            />

            <Route
              path="/RaterDashboard"
              element={
                <PrivateGroupRoute
                  allowedGroups={[process.env.REACT_APP_DASHBOARD_GROUP]}
                  sessionData={sessionData}
                >
                  <DashboardRater sessionData={sessionData} />
                </PrivateGroupRoute>
              }
            />

            <Route
              path="/resources"
              element={
                <PrivateGroupRoute
                  allowedGroups={[process.env.REACT_APP_DASHBOARD_GROUP]}
                  sessionData={sessionData}
                >
                  <Resources sessionData={sessionData} />
                </PrivateGroupRoute>
              }
            />

            <Route
              path="/safety"
              element={
                <PrivateGroupRoute
                  allowedGroups={[process.env.REACT_APP_DASHBOARD_GROUP]}
                  sessionData={sessionData}
                >
                  <Safety sessionData={sessionData} />
                </PrivateGroupRoute>
              }
            />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </LibertateContextProvider>
  ) : (
    <></>
  );
}

export default App;
