/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-east-2",
  aws_cloud_logic_custom: [
    {
      name: "AdminQueries",
      endpoint: "https://yulxt20qo7.execute-api.us-east-2.amazonaws.com/rtia",
      region: "us-east-2",
    },
  ],
  aws_cognito_identity_pool_id:
    "us-east-2:aabfe22a-2fa1-4187-b18c-8ad297ac807c",
  aws_cognito_region: "us-east-2",
  aws_user_pools_id: "us-east-2_utN5Lt3XT",
  aws_user_pools_web_client_id: "6hoeeurn7uqgj2a4mbkl99uqva",
  oauth: {},
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
};

export default awsmobile;
