import React, { Component } from "react";
import { Link } from "react-router-dom";
import HeaderSubPart from "../HeaderSubPart/HeaderSubPart";
import styles from "../../components/Header/Header.module.scss";

let RTIA_Logo = require("../../assets/images/RTlogo.png");
let insurecomplogo = require("../../assets/images/secondarylogo.png");

let isMobile = window.innerWidth < 900;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logoSize: "large",
      userGroups: [],
    };
  }

  componentDidMount = () => {
    let isLoggedIn,
      emailID,
      userGroups = [];
    try {
      isLoggedIn = this.props.sessionData;
      // console.log("isLoggedIn: ", isLoggedIn);
      if (isLoggedIn?.attributes?.name) {
        emailID = isLoggedIn.attributes.name;
      } else {
        emailID = isLoggedIn.attributes.email.split("@")[0];
      }
      let userGroups =
        isLoggedIn.signInUserSession.accessToken.payload["cognito:groups"];
      this.setState({ userGroups });
      // console.log("emailID: ", emailID, userGroups);
    } catch (error) {
      // console.log("comp did update err", error);
    }

    if (emailID) {
      this.setState({ emailID });
    }
  };

  render() {
    let { logoSize, userGroups } = this.state;
    let { showTab } = this.props;
    let baGroups = userGroups.includes(process.env.REACT_APP_LANDING_GROUP);

    return (
      <>
        <nav
          id="navbarMain"
          className={
            this.props.loggedInUser === undefined
              ? `${styles.new_navbar} container-fluid  ${styles.nav_fontsize}`
              : `${styles.new_navbar_login} container-fluid  ${styles.nav_fontsize}`
          }
        >
          <div
            className="d-flex justify-content-between align-items-center w-100 no-gutters"
            style={{ height: "120px", marginBottom: "40px" }}
          >
            {process.env.REACT_APP_MODE !== "beta" ? (
              <a
                href="https://risktransfer.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={styles.imagesizelogo}
                  id="imagesizelogo"
                  style={
                    logoSize === "large"
                      ? {
                          height: "100px",
                          marginLeft: "2rem",
                          marginTop: "2rem",
                        }
                      : { height: "100px", marginLeft: "1.6rem" }
                  }
                  src={RTIA_Logo}
                  alt="libertate logo"
                />
              </a>
            ) : (
              <div></div>
            )}

            {/* <Link
              to={"/"}
              className={`navbar-brand mx-0 d-flex align-items-center ${styles.icomplink}`}
            > */}

            {isMobile ? (
              " "
            ) : (
              <HeaderSubPart
                showTab={showTab}
                sessionData={this.props.sessionData}
              />
            )}
            <Link to={baGroups ? "/landingpage" : null}>
              {" "}
              <img
                className={styles.imagesizelogoicomp}
                id="imagesizelogoicomp"
                style={{
                  cursor: baGroups ? "pointer" : "default", // Dynamically set the cursor
                  height: logoSize === "large" ? "70px" : "71px",
                  marginRight: logoSize === "large" ? "2rem" : "1rem",
                  marginTop: logoSize === "large" ? "2rem" : "1rem",
                }}
                src={insurecomplogo}
                alt="insurecomp logo"
              />
            </Link>

            {/* </Link> */}
          </div>
        </nav>
      </>
    );
  }
}

export default Header;
