// utils/axiosInstance.js

import axios from "axios";
import { getHeader } from "../utils/common"; // Adjust the path as needed
import { ENDPOINTS } from "../constants/ApiEndpoints";

// Create an instance of axios
const axiosInstance = axios.create();

// Request interceptor to add headers to each request
axiosInstance.interceptors.request.use(
  async (config) => {
    try {
      const headers = await getHeader();

      // Check if the URL matches specific endpoints
      const isFileUploadAcord = config.url.includes(
        ENDPOINTS.FILE_UPLOAD_ACORD
      );
      const isOcrDocDemoAcord = config.url.includes("ocr-doc-demo-acord"); // Simplified check for URL

      // Only add headers if not matching specific endpoints
      if (!isFileUploadAcord && !isOcrDocDemoAcord) {
        config.headers = { ...config.headers, ...headers.headers };
      }

      // console.log("Request Config:", JSON.stringify(config, null, 2));

      return config;
    } catch (error) {
      console.error("Error in request interceptor:", error);
      return Promise.reject(error);
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor for handling responses globally
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Handle errors globally
    console.error("Error in API request:", error);
    return Promise.reject(error);
  }
);

export default axiosInstance;
