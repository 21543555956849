let mode;

if (process.env.REACT_APP_MODE) {
  mode = process.env.REACT_APP_MODE;
} else {
  mode = "dev";
}

var localURL = "http://localhost:3000/dev";

export var demoAuthUrl =
  "https://toyg4ps5yl.execute-api.us-east-1.amazonaws.com/dev";

// server_coreSetURL1;
export var awsUrl =
  "https://3hyg70a6k7.execute-api.us-east-1.amazonaws.com/dev";
var awsUrlBeta = "";
var awsUrlProd = "https://6p62g3tn0h.execute-api.us-east-1.amazonaws.com/prod";
export var awsAcordUpload =
  "https://5swuplkv50.execute-api.us-east-1.amazonaws.com/dev";

// server_coreSetURL2;
export var awsUrl2 =
  "https://ulk2h3sjjk.execute-api.us-east-1.amazonaws.com/dev";
var awsUrlBeta2 = "";
var awsUrlProd2 = "https://fra0epe8pj.execute-api.us-east-1.amazonaws.com/prod";

// dashboard URL
export var dashboardUrl =
  "https://dmuwt3vd0e.execute-api.us-east-1.amazonaws.com/dev";
var dashboardUrlProd = "";
//mode = "local2";
if (mode === "local") {
  awsUrl = awsUrl2 = localURL;
} else if (mode === "local1") {
  awsUrl = localURL;
  awsAcordUpload = localURL;
} else if (mode === "local2") {
  awsUrl2 = localURL;
  awsAcordUpload = localURL;
} else if (mode === "locald") {
  dashboardUrl = localURL;
} else if (mode === "prod") {
  awsUrl = awsUrlProd;
  awsUrl2 = awsUrlProd2;
  dashboardUrl = dashboardUrlProd;
} else if (mode === "beta") {
  awsUrl = awsUrlBeta;
  awsUrl2 = awsUrlBeta2;
  dashboardUrl = dashboardUrlProd;
}

export const versionNumber = "1.0.4 (m)";
