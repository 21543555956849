import React from "react";
import ReactDOM from "react-dom/client";
import "../src/assets/styles/custom.scss";
import "../src/assets/styles/index.scss";
import App from "./App";
import { unregister } from "./registerServiceWorker";
import config from "./aws-exports";
import { Amplify } from "aws-amplify";
Amplify.configure(config);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
unregister();
