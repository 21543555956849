import React, { useEffect, useState } from "react";

import { Nav } from "react-bootstrap";
import Header from "../../components/Header/Header";
import styles from "./workersPage.module.scss";

const WorkersPage = (props) => {
  const { sessionData } = props;

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 900);
  const [emailID, setemailID] = useState("");
  const [showTab, setShowTab] = useState(false);
  const [userGroups, setuserGroups] = useState([]);

  useEffect(() => {
    async function authentication() {
      try {
        let email = sessionData?.attributes?.email?.split("@")[0];

        console.log(email);

        setemailID(email);

        let userGroups =
          sessionData.signInUserSession.accessToken.payload["cognito:groups"];

        if (userGroups?.includes(process.env.REACT_APP_DEMO_GROUP)) {
          setIsDemoLogin(true);
        }

        setuserGroups({ userGroups });

        console.log("emailID: ", emailID, userGroups);
      } catch (error) {
        console.log("comp did update err", error);
      }

      if (emailID) {
        setuserGroups({ emailID });
      }
    }

    authentication();
  }, [sessionData, emailID]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 900);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      {isMobileView ? (
        <nav className="navwidth newquote-sidebar bg-transparent">
          <input
            type="checkbox"
            id="menu"
            name="menu"
            className="m-menu__checkbox bg-white"
          />

          <label className="m-menu__toggle" htmlFor="menu">
            <svg
              width="35"
              height="35"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#000"
              strokeWidth="2"
              strokeLinecap="butt"
              strokeLinejoin="arcs"
            >
              <line x1="3" y1="12" x2="21" y2="12"></line>

              <line x1="3" y1="6" x2="21" y2="6"></line>

              <line x1="3" y1="18" x2="21" y2="18"></line>
            </svg>
          </label>

          <label className="m-menu__overlay" htmlFor="menu"></label>

          <div className="m-menu">
            <div className="m-menu__header">
              <label className="m-menu__toggle" htmlFor="menu">
                <svg
                  width="35"
                  height="35"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#000000"
                  strokeWidth="2"
                  strokeLinecap="butt"
                  strokeLinejoin="arcs"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>

                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </label>

              <span></span>
            </div>

            <Nav.Item>
              <Nav.Link className="sidebar-heading">
                <div className="d-flex align-items-center text-white pt-3">
                  <span className="LoginUser">{emailID}</span>

                  <span>
                    {isMobileView ? (
                      <li className="no-bullets">
                        <a
                          className="dropdown-item"
                          onClick={() => {
                            logout();
                          }}
                        >
                          Logout <i className="bi-person-fill"></i>
                        </a>
                      </li>
                    ) : (
                      " "
                    )}
                  </span>
                </div>
              </Nav.Link>
            </Nav.Item>

            <hr
              style={{
                width: "90%",

                backgroundColor: "grey",
              }}
            />

            {isMobileView ? (
              <div>
                <a
                  className="dashboard-link-mobile text-white"
                  href={"/Dashboard"}
                >
                  Dashboard
                </a>
              </div>
            ) : (
              ""
            )}
          </div>
        </nav>
      ) : (
        <div className={`d-flex bg-light ${styles.dashboardHeader}`}>
          <Header sessionData={sessionData} showTab={showTab} />
        </div>
      )}

      <div className={styles.header_border}></div>

      <div className={styles.workers_header}>
        <div className={styles.heading}> Workers’ Compensation Resources </div>
        <div className={styles.headcontent}>
          <b>OSHA-</b> The Occupational Safety and Health Administration is the primary authority for workplace safety in the United States.  Their website is full of materials designed to assist employers in meeting safety compliance measure and protect worker safety and health.  The links below lead to groupings of tools to assist in navigating through the available resources.
        </div>
        <div>
          <a
            href="https://www.osha.gov/coronavirus"
            target="_blank"
          >
            <div className={styles.link_style}>OSHA - CoVid 19 Resources</div>
          </a>
        </div>
        <div>
          <div className={styles.sub_header}>Administrative Links:</div>

          <a
            href="https://www.osha.gov/contactus/bystate"
            target="_blank"
          >
            <div className={styles.link_style}>OSHA Area Offices Contat Map</div>
          </a>
          <a
            href="https://www.osha.gov/recordkeeping/presentations"
            target="_blank"
          >
            <div className={styles.link_style}>
              OSHA Recordkeeping
            </div>
          </a>
          <a
            href="https://www.osha.gov/stateplans"
            target="_blank"
          >
            <div className={styles.link_style}>
              OSHA State Plans
            </div>
          </a>
          <a
            href="https://www.osha.gov/contactus/bystate"
            target="_blank"
          >
            <div className={styles.link_style}>
              OSHA Claim Reporting Requirements
            </div>
          </a>
          <a
            href="https://risktransfer.egnyte.com/dl/cOn9gYoN5r/113427_OSHA_Injury_Reporting_Flowchart.pdf_"
            target="_blank"
          >
            <div className={styles.link_style}>
              OSHA Claim Reporting Flowchart
            </div>
          </a>
          <a
            href="https://www.osha.gov/sites/default/files/publications/osha3165.pdf"
            target="_blank"
          >
            <div className={styles.link_style}>
              OSHA Posters - English
            </div>
          </a>
          <a
            href="https://risktransfer.egnyte.com/fl/00vG2YUi23/Spanish_"
            target="_blank"
          >
            <div className={styles.link_style}>
              OSHA Posters - Spanish
            </div>
          </a>
        </div>
        <div>
          <div className={styles.sub_header}>Safety Programs:</div>

          <a
            href="https://risktransfer.egnyte.com/dl/cf86ssq98m/Written_Safety_Programs_Required_By_OSHA%5B1%5D.doc.docx_"
            target="_blank"
          >
            <div className={styles.link_style}>OSHA Required Written Safety Programs  </div>
          </a>
          <a
            href="https://www.osha.gov/complianceassistance/sampleprograms"
            target="_blank"
          >
            <div className={styles.link_style}>
              OSHA Required Written Safety Programs - Templates
            </div>
          </a>
          <a
            href="https://www.osha.gov/apps-and-etools"
            target="_blank"
          >
            <div className={styles.link_style}>
              OSHA eTools and Applications
            </div>
          </a>
        </div>
        <div>
          <div className={styles.sub_header}>Cal/OSHA:</div>

          <a
            href="https://www.dir.ca.gov/dosh/puborder.asp#IIPP"
            target="_blank"
          >
            <div className={styles.link_style}>CAL OSHA Injury & Illness Prevention Programs</div>
          </a>
          <a
            href="https://www.dir.ca.gov/dosh/dosh_publications/COVIDOnePageFS.pdf"
            target="_blank"
          >
            <div className={styles.link_style}>
              CAL OSHA CoVid 19 Model Prevention Program
            </div>
          </a>
        </div>
        <div>
          <div className={styles.sub_header}>Safety Training / Toolbox Talks:</div>

          <a
            href="https://www.osha.gov/sites/default/files/publications/osha2254.pdf"
            target="_blank"
          >
            <div className={styles.link_style}>OSHA Training Requirement References by Occupation </div>
          </a>
          <a
            href="https://www.osha.gov/video/"
            target="_blank"
          >
            <div className={styles.link_style}>
              OSHA Web Videos
            </div>
          </a>
          <a
            target="_blank"
            href="https://www.osha.gov/publications/all"
          >
            <div className={styles.link_style}>
              OSHA Progam Level Fact Sheets & Publications
            </div>
          </a>
          <a
            href="https://www.tdi.texas.gov/wc/safety/videoresources/index.html"
            target="_blank"
          >
            <div className={styles.link_style}>
              Texas Dept of Insurance (TDI) - Safety Resources & Videos
            </div>
          </a>
          <a
            href="https://www.elcosh.org/index.php"
            target="_blank"
          >
            <div className={styles.link_style}>
              Construction Occup Safety & Health - Electronic Library
            </div>
          </a>
        </div>
        <div>
          <div className={styles.sub_header}>DOT Compliance</div>

          <a
            href="https://www.transportation.gov/odapc/compliance-with-dot-drug-and-alcohol-testing-regulations"
            target="_blank"
          >
            <div className={styles.link_style}>DOT Drug Testing Regulation</div>
          </a>
          <a
            href="https://www.transportation.gov/odapc/documents"
            target="_blank"
          >
            <div className={styles.link_style}>
              DOT Drug Testing Documents and Forms
            </div>
          </a>
          <a
            href="https://csa.fmcsa.dot.gov/safetyplanner/documents/Forms/Driver%20Qualification%20Checklist_508.pdf"
            target="_blank"
          >
            <div className={styles.link_style}>
              DOT/FMCSA – Driver File Documentation Checklist
            </div>
          </a>
          <a
            href="https://www.fmcsa.dot.gov/regulations/hours-of-service"
            target="_blank"
          >
            <div className={styles.link_style}>
              DOT Drivers - Hours of Service (HOS)
            </div>
          </a>
        </div>
        <div>
          <div className={styles.sub_header}>NCCI Experience Mods</div>
          <a
            href="https://www.ncci.com/Articles/Documents/UW_ABC_Exp_Rating.pdf"
            target="_blank"
          >
            <div className={styles.link_style}>NCCI ABC's of Experience Rating</div>
          </a>
          <a
            href="https://www.ncci.com/Articles/Pages/II_Rediscover-NCCIs-Learning-Center.aspx"
            target="_blank"
          >
            <div className={styles.link_style}>NCCI Video Learning Center</div>
          </a>
          <a
            href="https://www.ncci.com/Articles/Pages/Insights-2023-in-Review-The-Latest-Results-for-Workers-Compensation.aspx"
            target="_blank"
          >
            <div className={styles.link_style}>NCCI May 2024  - State of the Line for 2023</div>
          </a>
          <a
            href="https://www.ncci.com/Articles/Pages/II_PEO_EmployeeLeasingGuidetoStateSpecificRequirements.aspx"
            target="_blank"
          >
            <div className={styles.link_style}>
              NCCI - Professional Employer Organizatoins (PEO) - Guide to State Specific Requirements
            </div>
          </a>
        </div>
        <div>
          <div className={styles.sub_header}>
            Claims Management / Return to Work
          </div>
          <a
            href="https://portal.i3analytics.com/wp-content/uploads/2020/01/Division-of-WC-Websites.pdf"
            target="_blank"
          >
            <div className={styles.link_style}>
              State Division of WC Websites
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default WorkersPage;
